import axios from 'axios'

function reOrderTheme(id, data) {
  return axios.post(`theme/re_order/${id}`, data)
}

function addTheme(data) {
  return axios.post('theme', data)
}

function getThemeById(id) {
  return axios.get(`theme/${id}`)
}

function editTheme(id, data) {
  return axios.put(`theme/${id}`, data)
}

function deleteTheme(id) {
  return axios.delete(`theme/${id}`)
}

function getThemeListing() {
  return axios.get('theme/admin')
}
export default {
  getThemeListing,
  reOrderTheme,
  addTheme,
  getThemeById,
  editTheme,
  deleteTheme,
}
